import ls from '../../library/localStorage';
import { version } from '../../../package.json';

export default {
  namespaced: true,
  state: {
    alert: ls.get('alert') || [],
    call_session: ls.get('activeCall') || {},
    packageVersion: version,
  },
  getters: {
  },
  mutations: {
    setAlert(state, [data, type]) {
      state.alert.push({
        id: Date.now(),
        type,
        data,
      });
    },
    removeAlert(state, id) {
      state.alert = state.alert.filter((item) => item.id !== id);
    },
    clearAlert(state) {
      state.alert = [];
    },
    clearActiveCall(state) {
      state.call_session = {};
    },
    setActiveCall(state, session) {
      state.call_session = session;
    },
  },
  actions: {
    alertInfo({ state, commit }, data) {
      commit('setAlert', [data, 'info']);
      ls.set('alert', state.alert);
    },
    alertSuccess({ state, commit }, data) {
      commit('setAlert', [data, 'success']);
      ls.set('alert', state.alert);
    },
    alertError({ state, commit }, data) {
      commit('setAlert', [data, 'danger']);
      ls.set('alert', state.alert);
    },
    removeAlert({ state, commit }, id) {
      commit('removeAlert', id);
      ls.set('alert', state.alert);
    },
    clearAlert({ commit }) {
      commit('clearAlert');
      ls.remove('alert');
    },
    setActiveCall({ commit }, session) {
      console.log('store ui');
      commit('setActiveCall', session);
      ls.set('activeCall', session);
    },
    clearActiveCall({ commit }) {
      commit('clearActiveCall');
      ls.remove('activeCall');
    },
  },
};
