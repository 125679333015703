import Vue from 'vue';

import JsSIP from 'jssip';
import store from '../store';

let socket_sip = null;
let stream_local_sip = null;

Vue.mixin({
  data() {
    return {};
  },
  computed: {
    authSip() {
      return store.state.auth.sip || {};
    },
    SIPsocket() {
      if (this.authSip?.access.extension) {
        const connect_sip = new JsSIP.WebSocketInterface(`wss://${store.state.auth.sip.access.domain}:${store.state.auth.sip.access.port}/ws`);
        const configuration = {
          sockets: [connect_sip],
          uri: `sip:${store.state.auth.sip.access.extension}@${store.state.auth.sip.access.domain}`,
          password: process.env.VUE_APP_SIP_KEY,
          // password: store.state.auth.sip.access.key,
          display_name: store.state.auth.userProfile.name,
        };
        socket_sip = new JsSIP.UA(configuration);
        return socket_sip;
      }
      return null;
    },
    streamLocal() {
      return stream_local_sip;
    },
  },
  mounted() {
  },
  methods: {
    checkSocketFalse() {
      return !store.state.workspace.activeWorkspace._id || store.state.auth.userProfile.agents[0]?.workspace_id !== store.state.workspace.activeWorkspace._id || !store.state.auth.sip?.access?.extension;
    },
    async outgoingCall(convo) {
      let selected_contact = {};
      let cnv = {};
      if (convo.contacts) {
        selected_contact = convo.contacts.find((v) => v.channel === 'whatsapp');
        cnv = convo;
      } else {
        selected_contact = convo;
        cnv = {
          contacts: [selected_contact],
        };
      }
      let to_number = selected_contact.phone_number;
      if (selected_contact.phone_number.startsWith('62')) {
        to_number = `0${selected_contact.phone_number.substring(2)}`;
      } else {
        to_number = selected_contact.phone_number;
      }
      const constraints = {
        audio: {
          noiseSuppression: true,
          echoCancellation: true,
          autoGainControl: true,
        },
        // audio: true,
        video: false,
      };
      const local_stream = await navigator.mediaDevices.getUserMedia(constraints).catch(() => {
        navigator.permissions.query({ name: 'microphone' });
      });
      stream_local_sip = local_stream;
      const options = {
        eventHandlers: {
          peerconnection: ((e) => {
            console.log('call is in peer connecting');
            console.log(e);
            if (e.peerconnection) {
              e.peerconnection.ontrack = ((event) => {
                console.log(event);
              });
            } else {
              console.log('onSessionDescriptionHandler fired without a peerConnection');
            }
          }),
        },
        mediaConstraints: constraints,
        mediaStream: local_stream,
        data: {
          conversation: cnv,
        },
      };
      const session = socket_sip.call(`sip:${to_number}@${store.state.auth.sip.access.domain}`, options);
      await this.$store.dispatch('ui/setActiveCall', {
        callReceived: true,
        callSession: session.id,
        data: session.data,
        toNumber: selected_contact.phone_number,
        // socket: this.SIPsocket,
      });
    },
  },
});
